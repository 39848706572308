import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import LayoutWithSidebar from "../layout/withSidebar";
import Seo from "../components/seo";
import "../css/services.css";

const ServicesPage = () => {
  const pageTitle = "Services";
  const pageSubtitle =
    '"Do I really look like a guy with a plan?" - The Dark Knight, 2008';
  return (
    <LayoutWithSidebar pageTitle={pageTitle} pageSubtitle={pageSubtitle}>
      <Seo title="Services that I provide" />
      <div className="row gy-3">
        <div className="col-12 col-xl-6">
          <div className="card mb-3">
            <div className="card-body">
              <h2 className="service-card-title">UI/UX Design</h2>
            </div>
          </div>

          <div className="card card-outline mb-3">
            <div className="card-body">
              <StaticImage
                className="static-image contact-img mb-3"
                src="../images/mefactor@25x.png"
                quality={95}
                alt="Profile picture"
              />
              <h3 className="sub-title">Designing your next application</h3>
              <p className="body-text">
                Let's turn your idea into a design that you can review, talk
                about, build upon, start a business with.
              </p>
            </div>
          </div>

          <div className="card card-outline mb-3">
            <div className="card-body">
              <StaticImage
                className="static-image contact-img mb-3"
                src="../images/oldtonew@1.25x.png"
                quality={95}
                alt="Profile picture"
              />
              <h3 className="sub-title">Redesigning your application</h3>
              <p className="body-text">
                Let's do the same with your existing application. See how we can
                improve and build upon it.
              </p>
            </div>
          </div>

          <div className="card card-outline card-alert mb-3">
            <div className="card-body">
              <div className="alert-header">
                <StaticImage
                  className="alert-header-icon"
                  src="../images/error.png"
                  quality={100}
                  layout="fixed"
                  width={32}
                  height={32}
                  alt="Profile picture"
                />
                <h3 className="m-0">Be aware!</h3>
              </div>
              <div className="text-body">
                <p>
                  I'm not a product designer or UX specialist. Although I'm an
                  expert on the user experience part of designing a user
                  interface, which includes familiarity with interface
                  guidelines, accessibility needs, web & mobile standards,
                  platform limits, ... but both a product designer and a UX
                  specialist duties contains much more like user research and
                  user survey, user persona and story map, etc.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-6">
          <div className="card mb-3">
            <div className="card-body">
              <h2 className="service-card-title">Front-end Developer</h2>
            </div>
          </div>

          <div className="card card-outline mb-3">
            <div className="card-body">
              <StaticImage
                className="static-image contact-img mb-3"
                src="../images/codeofhessam.jpg"
                quality={95}
                alt="Profile picture"
              />
              <h3 className="sub-title">Convert your designs to code</h3>
              <p className="body-text">
                Building front-end applications based upon your existing designs
                and adopting them to web standards. Building and delivering on
                ...
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="card card-outline mb-3">
                <div className="card-body py-3">
                  <span className="">HTML / CSS / JAVASCRIPT</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="card card-outline mb-3">
                <div className="card-body py-3">
                  <span className="">REACT / NEXT / GATSBY</span>
                </div>
              </div>
            </div>
          </div>

          <div className="card card-outline mb-3">
            <div className="card-body">
              <StaticImage
                className="static-image contact-img mb-3"
                src="../images/me-welcoming.jpg"
                quality={95}
                alt="Profile picture"
              />
              <h3 className="sub-title">Hire me as an member</h3>
              <p className="body-text">
                Need me for more than a single project? I'm available for
                full-time commitment depending on the team and project.
              </p>
            </div>
          </div>
        </div>
      </div>
    </LayoutWithSidebar>
  );
};

export default ServicesPage;
